import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import * as firebase from 'firebase';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
//import { AdmobFreeService } from './service/admobfree.service.ts.service';
//import { AdMobFree } from '@ionic-native/admob-free/ngx';


firebase.initializeApp({
  apiKey: "AIzaSyA4oQn_hjjE39OYDZylxNQSuDg9Zv_jG4Q",
  authDomain: "istoria-93934.firebaseapp.com",
  databaseURL: "https://istoria-93934.firebaseio.com",
  projectId: "istoria-93934",
  storageBucket: "istoria-93934.appspot.com",
  messagingSenderId: "551610112355",
  appId: "1:551610112355:web:2eafd6418dd7d408325b56",
  measurementId: "G-5JXXC8GSJL"
});
firebase.analytics();


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

